import React from "react";
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import MemberDetails from '../../components/TeamDetails/MemberDetails/MemberDetails'
import ContentBlock from '../../components/TeamDetails/ContentBlock/ContentBlock'
import TeamMember from '../../components/TeamDetails/TeamMember/TeamMember'


const TeamDetails =()=> {
  const [state,setState] = React.useState({
    showMenu:false
})

const handlerClick = () => {

        console.log('okkk')
        setState({...state,showMenu:!state.showMenu})
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }
   
}

return(
    <>
    <div className={state.showMenu ? "wrapper open-search-block" : "wrapper"}>
     <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
     <MemberDetails/>
        <ContentBlock/>
        <TeamMember/>
        <Footer />
        </div>  
        </>
    )
}

export default TeamDetails;